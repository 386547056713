'use client';

import * as React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
export default function LegalPagesSidebar(): React.ReactElement {
  const pathname = usePathname();
  return <div className="mx-4 flex w-full flex-col gap-1 text-left text-sm" data-sentry-component="LegalPagesSidebar" data-sentry-source-file="index.tsx">
      {[{
      url: '/community-guidelines',
      name: 'Community Guidelines'
    }, {
      url: '/privacy-policy',
      name: 'Privacy Policy'
    }, {
      url: '/cookie-policy',
      name: 'Cookie Policy'
    }, {
      url: '/terms-and-conditions',
      name: 'Terms and Conditions'
    }, {
      url: '/trademark-disclosure',
      name: 'Trademark Disclosure'
    }, {
      url: '/dmca',
      name: 'DMCA'
    }].map(link => {
      return pathname === link.url ? <Link key={`legalLink_${link.name}`} href={link.url} className="pointer-events-none w-full text-nowrap rounded-lg bg-neutral-100 px-4 py-2 dark:bg-neutral-700">
            {link.name}
          </Link> : <Link key={`legalLink_${link.name}`} href={link.url} className="w-full text-nowrap rounded-lg px-4 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700">
            {link.name}
          </Link>;
    })}
    </div>;
}